<template>
  <section class="main">
    <div class="row">
      <div class="col-lg-3 col-xl-3">
        <div class="card">
          <div class="card-body text-center">
            <img src="@/assets/images/user.png" alt="profile-image" class="rounded-circle avatar-xl img-thumbnail">
            <h4 class="mt-3 mb-0">{{ this.userGet.full_name }}</h4>
            <div class="mt-3">
              <h5 class="text-lg-start">Информация</h5>
              <div class="user-info">
                <span class="info-title">ФИО: </span>
                <span class="info-text">{{ this.userGet.full_name ? this.userGet.full_name : '-' }}</span>
              </div>
              <div class="user-info ">
                <span class="info-title">Телефон: </span>
                <span class="info-text">{{ this.userGet.phone }}</span>
              </div>
              <div class="user-info">
                <span class="info-title">Email: </span>
                <span class="info-text">{{ this.userGet.email ? this.userGet.email : '-' }}</span>
              </div>
              <div class="user-info">
                <span class="info-title">Баллов: </span>
                <span class="info-text">{{ this.userGet.scores }}</span>
              </div>
              <div class="user-info">
                <span class="info-title">Средний чек: </span>
                <span class="info-text">{{
                    this.userGet.avgAmountOrder > 0 ? parseFloat(this.userGet.avgAmountOrder).toFixed(2) : parseFloat(this.userGet.avg_check).toFixed(2)
                  }} руб.</span>
              </div>
              <div class="user-info">
                <span class="info-title">Всего заказов: </span>
                <span class="info-text">{{ this.userGet.total_order_count }} шт.</span>
              </div>
              <div class="user-info">
                <span class="info-title">Дата последнего заказ: </span>
                <span class="info-text">{{
                    this.userGet.orders.length > 0 ? this.userGet.orders[this.userGet.orders.length - 1].date : this.userGet.last_order_date
                  }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-9 col-xl-9">
        <div class="card">
          <div class="card-body">
            <div class="table-title">
              Список заказов
            </div>
            <p v-if="this.userGet.orders.length == 0">Если есть дата последнего заказа, но в таблице ниже нет заказов,
              значит пользователь со старой версии сайта {{ appName.toLowerCase() }}</p>
            <table class="table table-striped table-hover">
              <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Общая сумма
                  <vue-custom-tooltip label="С вычетом бонусов"><i class="far fa-question-circle"></i>
                  </vue-custom-tooltip>
                </th>
                <th scope="col">Доставка</th>
                <th scope="col">Статус</th>
                <th scope="col">Способ оплаты</th>
                <th scope="col">Адрес</th>
                <th scope="col">Интервал доставки</th>
                <th scope="col">ECO ID
                  <vue-custom-tooltip label="ID в системе эко маркета"><i class="far fa-question-circle"></i>
                  </vue-custom-tooltip>
                </th>
                <th scope="col"></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in this.userGet.orders" :key="item.id">
                <th scope="row">{{ index + 1 }}</th>
                <td>{{ (parseFloat(item.amount) + parseFloat(item.delivery_price)).toFixed(2) }} руб.</td>
                <td>{{ item.delivery_price }} руб.</td>
                <td>{{ statusOrder(item.status) }}</td>
                <td>{{ typePayment(item.type_payment) }}</td>
                <td>{{ item.address }}</td>
                <td>{{ item.date_interval }} {{ item.time_interval }}</td>
                <td>{{ item.eco_id }}</td>
                <td class="actions">
                  <router-link :to="'/view-order/' + item.id">
                    <i class="fas fa-eye"></i>
                  </router-link>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-lg-4 col-xl-4">
        <div class="card">
          <div class="card-body">
            <div class="table-title">
              Адреса
            </div>
            <table class="table table-striped table-hover">
              <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Кв/офис</th>
                <th scope="col">Подъезд</th>
                <th scope="col">Этаж</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in this.userGet.userAddresses" :key="item.id">
                <th scope="row">{{ index + 1 }}</th>
                <td>{{ item.address }}</td>
                <td>{{ item.number }}</td>
                <td>{{ item.door }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-3">
        <div class="card">
          <div class="card-header">
            Начислить баллы
          </div>
          <div class="card-body">
            <div class="form-group mb-3">
              <label class="col-form-label">Количество</label>
              <input type="number" class="form-control" v-model="scores.score" :min="1">
            </div>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" :value="scores.needDate" v-model="scores.needDate">
              <label class="form-check-label">
                Начислить на дату
              </label>
            </div>
            <div class="form-group mb-3" v-if="scores.needDate">
              <label class="col-form-label">Выберите дату</label>
              <input type="datetime-local" class="form-control" v-model="scores.date">
            </div>
            <div class="d-flex justify-content-end">
              <button type="button" class="btn btn-success mt-2" @click="addScore">
                Начислить
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card">
          <div class="card-header">
            Списать баллы
          </div>
          <div class="card-body-low">
            <div class="form-group">
              <label class="col-form-label">Количество</label>
              <input type="number" class="form-control" v-model="rScore.score" :min="1">
            </div>
            <div class="form-group">
              <label class="col-form-label">Причина списания</label>
              <textarea class="form-control" v-model="rScore.comment" placeholder="Введите текст" rows="3"/>
            </div>
            <div class="d-flex justify-content-end mt-2">
              <button type="button" class="btn btn-success" @click="removeScore">
                Списать
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: 'view-user',
  data: function () {
    return {
      appName: process.env.VUE_APP_NAME,
      id: this.$route.params.id,
      scores: {
        users_id: this.$route.params.id,
        score: 1,
        needDate: false,
        date: null,
      },
      rScore: {
        users_id: this.$route.params.id,
        score: 1,
        comment: ''
      },
    }
  },
  computed: mapGetters(['userGet']),
  async mounted () {
    this.getUser();
  },
  methods: {
    removeScore: function () {
      this.$store.dispatch('removeScore', this.rScore).then(() => {
        this.rScore.score = 1;
        this.rScore.comment = '';
      });
    },
    addScore: function () {
      this.$store.dispatch('addScore', this.scores).then(() => {
        this.scores.score = 1;
        this.scores.needDate = false;
        this.scores.date = null;
      });
    },
    getUser: function () {
      this.$store.dispatch('getUser', this.id);
    },
    statusOrder: function (value) {
      let status = '';
      switch (value) {
        case 'created':
          status = 'Создан';
          break;
        case 'canceled':
          status = 'Отменен';
          break;
        case 'delivered':
          status = 'Доставлен';
          break;
        case 'on_the_way':
          status = 'Доставляется';
          break;
        case 'handed_over_for_picking':
          status = 'Собран';
          break;
        case 'accepted':
          status = 'Принят';
          break;
        default:
          status = value;
          break;
      }
      return status;
    },
    typePayment: function (value) {
      let type = '';
      switch (value) {
        case 1:
          type = 'Наличные';
          break;
        case 3:
          type = 'Безналичные';
          break;
      }
      return type;
    },
  }
}
</script>

<style lang="scss">
.card-body-low {
  padding: 0.5rem 0.8rem 0.5rem 0.8rem;
}

.avatar-xl {
  height: 6rem;
  width: 6rem;
}

.user-info {
  text-align: left;

  .info-title {
    color: #6c757d;
  }

  .info-text {
    color: #98a6ad;
  }
}
</style>